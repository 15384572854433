import React from "react";
import Layout from "../../Layout";
import "./style.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = [
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4697.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4711.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4712.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4713.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4714.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4715.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/IMG_4717.png",
];

const settings = {
  // dots: true,
  infinite: true,
  arrows: true,
  // fade: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
};
export default function Academics() {
  return (
    <Layout>
      <div className="academics">
        <h1 className="heading">Academics</h1>

        <p className="academics__text">
          Chinmaya Vidyalaya, Vasant Vihar, New Delhi is affiliated to the CBSE
          with classes from Nursery to XII.
          <br />
          <br />
          At Chinmaya, teaching is student-centred, project-based, and
          incorporates the multiple intelligence concept. While the general
          curriculum is followed by every student, he or she is also encouraged
          to develop talents in personal areas of interest.
          <br />
          <br />
          Thus, academic study at Chinmaya is far from limited to the academic
          curriculum. By nurturing a love of reading via our regular activities,
          right from class Nursery onwards, we ensure that students glean
          knowledge from books that contributes to holistic growth;
          complementing their studies, nurturing a moral outlook and reinforcing
          their individual growth.
          <br />
          <br />
          Honing of individual talent is also carried out through research-based
          methodologies and lessons in special workshops and labs, developing
          critical thinking and future proof technical skills.
        </p>

        <div className="academics__slider">
          <Slider {...settings}>
            {images.map((item, i) => (
              <div className="academics__slider__image">
                <img
                  key={`herodesk-${i}`}
                  alt="Hero Image"
                  loading="eager"
                  src={item}
                ></img>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Layout>
  );
}
